/*
 * Copyright 2025 Tridium Inc. All rights reserved.
 */
import React from 'react';
import {
  NiagaraButton2,
  NiagaraModal,
} from '@Niagara-Cloud-Suite/Niagara-Cloud-Suite.NiagaraManagementPlaneCommons-lib';
import { Modal } from '@scuf/common';
import './ConfigureNotificationsModal.scss';

interface IConfigureNotificationsModal {
  onClose: () => void;
}

export function ConfigureNotificationsModal({ onClose }: IConfigureNotificationsModal) {
  return (
    <NiagaraModal className='configuration-notifications-modal' open={true} size='large'>
      <Modal.Header>
        <div className='modal-heading'>Configure Notifications</div>
        <NiagaraButton2 type='secondary-link' icon='Close' iconColor='#606060' onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
      </Modal.Content>
      <Modal.Footer>
      </Modal.Footer>
    </NiagaraModal>
  );
}
